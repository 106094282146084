import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect } from "react";
import { Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import VerticalTableRow from "./VerticalTableRow";

export default observer(function DetailedselectedPair() {
    const { dataStore } = useStore();
    const { selectedPair, GetRun } = dataStore;

    useEffect(() => {
        if (selectedPair!.left === null) {
            GetRun(selectedPair!.timestamp, "L");
        }

        if (selectedPair!.right === null) {
            GetRun(selectedPair!.timestamp, "R");
        }
    }, [selectedPair, GetRun]);

    if (!selectedPair) {
        return <Fragment></Fragment>;
    }

    return (
        <Table selectable striped fixed compact singleLine unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign='center' colSpan='3'>
                        {selectedPair.raceEvent.name} -
                        {format(new Date(selectedPair.timestamp), "HH:mm:ss EEEE do MMMM yyyy")}
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell textAlign='center' colSpan='3'>
                        {selectedPair.category.name} - {selectedPair.round}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <VerticalTableRow
                    leftValue={selectedPair.left?.racenumber}
                    centerValue={"Race #"}
                    rightValue={selectedPair.right?.racenumber}
                />

                <VerticalTableRow
                    leftValue={selectedPair.left?.drivername}
                    centerValue={"Name"}
                    rightValue={selectedPair.right?.drivername}
                />

                {selectedPair.left?.index !== 0 && selectedPair.right?.index !== 0 && (
                    <VerticalTableRow
                        leftValue={selectedPair.left?.index}
                        centerValue={"Index"}
                        rightValue={selectedPair.right?.index}
                        dp={2}
                    />
                )}

                <VerticalTableRow
                    leftValue={selectedPair.left?.reaction}
                    centerValue={"Reaction"}
                    rightValue={selectedPair.right?.reaction}
                />
                <VerticalTableRow
                    leftValue={selectedPair.left?.eT60}
                    centerValue={"60' ET"}
                    rightValue={selectedPair.right?.eT60}
                />
                <VerticalTableRow
                    leftValue={selectedPair.left?.eT330}
                    centerValue={"330' ET"}
                    rightValue={selectedPair.right?.eT330}
                />
                <VerticalTableRow
                    leftValue={selectedPair.left?.eT594}
                    centerValue={"594' ET"}
                    rightValue={selectedPair.right?.eT594}
                />
                <VerticalTableRow
                    leftValue={selectedPair.left?.eT660}
                    centerValue={"1/8 ET"}
                    rightValue={selectedPair.right?.eT660}
                />
                <VerticalTableRow
                    leftValue={selectedPair.left?.sP660}
                    centerValue={"1/8 MPH"}
                    rightValue={selectedPair.right?.sP660}
                    dp={2}
                />
                {selectedPair.finish > 1 && (
                    <Fragment>
                        <VerticalTableRow
                            leftValue={selectedPair.left?.eT934}
                            centerValue={"934' ET"}
                            rightValue={selectedPair.right?.eT934}
                        />
                        <VerticalTableRow
                            leftValue={selectedPair.left?.eT1000}
                            centerValue={"1000' ET"}
                            rightValue={selectedPair.right?.eT1000}
                        />
                        <VerticalTableRow
                            leftValue={selectedPair.left?.sP1000}
                            centerValue={"1000 MPH"}
                            rightValue={selectedPair.right?.sP1000}
                            dp={2}
                        />
                    </Fragment>
                )}

                {selectedPair.finish >= 2 && (
                    <Fragment>
                        <VerticalTableRow
                            leftValue={selectedPair.left?.eT1254}
                            centerValue={"1254' ET"}
                            rightValue={selectedPair.right?.eT1254}
                        />
                        <VerticalTableRow
                            leftValue={selectedPair.left?.eT1320}
                            centerValue={"1320' ET"}
                            rightValue={selectedPair.right?.eT1320}
                        />
                        <VerticalTableRow
                            leftValue={selectedPair.left?.sP1320}
                            centerValue={"1320 MPH"}
                            rightValue={selectedPair.right?.sP1320}
                            dp={2}
                        />
                    </Fragment>
                )}

                <VerticalTableRow
                    leftValue={selectedPair.left?.result}
                    centerValue={"Result"}
                    rightValue={selectedPair.right?.result}
                />
                <VerticalTableRow
                    leftValue={selectedPair.left?.remarks}
                    centerValue={"Remarks"}
                    rightValue={selectedPair.right?.remarks}
                />
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell textAlign='center' colSpan={3}>
                        <strong>ETSlips.co.uk</strong>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
});
