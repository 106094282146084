import { observer } from "mobx-react-lite";
import { Modal } from "semantic-ui-react";
import { useStore } from "../../stores/store";

export default observer(function ModalContainer() {
    const { modalStore } = useStore();

    return (
        <Modal
            style={{ top: "auto", height: "auto", left: "auto" }}
            size='tiny'
            open={modalStore.modal.open}
            onClose={modalStore.closeModal}
            closeIcon
        >
            <Modal.Content>{modalStore.modal.body}</Modal.Content>
        </Modal>
    );
});
