import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class FilterStore {
    // Loaders
    loadingRaceEvents = false;
    loadingPrefixes = false;
    loadingCategories = false;
    loadingRounds = false;

    // Flags
    advanceFilterOpen = false;

    // Filter options
    eventOptions: Map<number, string> = new Map<number, string>();
    categoryOptions: Map<number, string> = new Map<number, string>();
    prefixOptions: Map<number, string> = new Map<number, string>();
    roundOptions: Map<string, string> = new Map<string, string>();

    constructor() {
        makeAutoObservable(this);
    }

    loadRaceEvents = async () => {
        this.loadingRaceEvents = true;
        const events = await agent.Filters.listRaceEvents();

        runInAction(() => {
            events.forEach((event) => {
                this.eventOptions.set(event.id, event.name);
            });
            this.loadingRaceEvents = false;
        });
    };

    loadCategories = async () => {
        this.loadingCategories = true;
        const categories = await agent.Filters.listCategories();

        runInAction(() => {
            categories.forEach((category) => {
                this.categoryOptions.set(category.id, category.name);
            });
            this.loadingCategories = false;
        });
    };

    loadPrefixes = async () => {
        this.loadingPrefixes = true;
        const prefixes = await agent.Filters.listPrefixes();

        runInAction(() => {
            prefixes.forEach((prefix) => {
                this.prefixOptions.set(prefix.id, prefix.name);
            });
            this.loadingPrefixes = false;
        });
    };

    loadRounds = async () => {
        this.loadingRounds = true;
        const rounds = await agent.Filters.listRounds();

        runInAction(() => {
            rounds.forEach((round) => {
                this.roundOptions.set(round, round);
            });
            this.loadingRounds = false;
        });
    };

    toggleFilter = () => {
        this.advanceFilterOpen = !this.advanceFilterOpen;
    };
}
