import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Filter } from "../models/Filter";
import { PageMetaData } from "../models/PageMetaData";
import { Pair } from "../models/Pair";
import { Run } from "../models/Run";

export default class DataStore {
    data: Pair[] = [];
    selectedPair: Pair | null = null;
    loadingData = false;
    loadingRun = false;
    filteredData = false;

    run: Run | null = null;
    pageMetaData: PageMetaData = {
        pageSize: 25,
        totalPages: 0,
        totalCount: 0,
        currentPage: 0,
    };
    filter: Filter | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    loadData = async () => {
        this.loadingData = true;
        this.filteredData = false;
        const data = await agent.Search.listPairs(this.pagingAxiosParams);

        runInAction(() => {
            this.pageMetaData = data.pageMetaData;
            data.data.forEach((pair) => {
                this.data.push(pair);
            });
            this.loadingData = false;
        });
    };

    filterData = async (filter: Filter) => {
        this.loadingData = true;
        this.filteredData = true;
        this.filter = filter;

        if (filter.pageNumber === 0) this.data = [];

        try {
            const data = await agent.Search.filterRuns(this.filterAxiosParams(filter));
            runInAction(() => {
                this.pageMetaData = data.pageMetaData;
                data.data.forEach((pair) => {
                    this.data.push(pair);
                });
                this.loadingData = false;
            });
        } catch {
            runInAction(() => {
                this.loadingData = false;
                this.data = [];
                this.pageMetaData.totalCount = 0;
                this.pageMetaData.totalPages = 0;
            });
        }
    };

    GetRun = async (timestamp: string, lane: string) => {
        this.loadingRun = true;

        const data = await agent.Search.getRun(this.GetRunParams(timestamp, lane));
        runInAction(() => {
            if (lane === "L") this.selectedPair!.left = data;
            if (lane === "R") this.selectedPair!.right = data;
            this.loadingRun = false;
        });
    };

    GetRunParams(timestamp: string, lane: string) {
        const params = new URLSearchParams();
        params.append("timestamp", timestamp);
        params.append("lane", lane);
        return params;
    }

    get pagingAxiosParams() {
        const params = new URLSearchParams();
        params.append("pageNumber", this.pageMetaData.currentPage.toString());
        return params;
    }

    filterAxiosParams(filter: Filter) {
        let params = this.pagingAxiosParams;
        if (filter.racenumber !== "") {
            params.append("rn", filter.racenumber);
        }
        if (filter.events?.length !== 0) {
            params.append("events", filter.events!.join(","));
        }
        if (filter.categories?.length !== 0) {
            params.append("categories", filter.categories!.join(","));
        }
        if (filter.prefixes?.length !== 0) {
            params.append("prefixes", filter.prefixes!.join(","));
        }
        if (filter.rounds?.length !== 0) {
            params.append("rounds", filter.rounds!.join(","));
        }

        if (filter.minET !== "") {
            params.append("minET", filter.minET.toString());
        }
        if (filter.maxET !== "") {
            params.append("maxET", filter.maxET.toString());
        }
        if (filter.minMPH !== "") {
            params.append("minMPH", filter.minMPH.toString());
        }

        if (filter.maxMPH !== "") {
            params.append("maxMPH", filter.maxMPH.toString());
        }

        if (filter.lane !== "_") {
            params.append("lane", filter.lane);
        }
        if (filter.trackLength !== "99") {
            params.append("trackLength", filter.trackLength.toString());
        }
        return params;
    }

    setSelectedPair = (pair: Pair) => {
        this.selectedPair = { ...pair };
    };

    incrementPageNumber = () => {
        this.pageMetaData.currentPage++;
    };

    reset = () => {
        this.data = [];
        this.pageMetaData.currentPage = 0;
    };
}
