import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableFooter, TableRow } from "semantic-ui-react";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import DataTableRowPair from "./dataTable/DataTableRowPair";
import { useStore } from "../../app/stores/store";
import InfiniteScroll from "react-infinite-scroller";

export default observer(function DataTable() {
    const { dataStore } = useStore();
    const { loadData, data, loadingData, pageMetaData, filter, filterData, incrementPageNumber } = dataStore;
    const [loadingNext, setLoadingNext] = useState(false);

    useEffect(() => {
        loadData();
    }, [loadData]);

    function handleGetNext() {
        if (pageMetaData.currentPage + 1 === pageMetaData.totalPages) return;
        setLoadingNext(true);
        incrementPageNumber()
        if (filter !== null) {
            filterData(filter).then(() => setLoadingNext(false));
        } else {
            loadData().then(() => setLoadingNext(false));
        }
    }

    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={handleGetNext}
            // hasMore={false}
            hasMore={!loadingNext && pageMetaData.currentPage < pageMetaData.totalPages}
            initialLoad={false}
        >
            <Table size='small' selectable compact singleLine striped unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Time</Table.HeaderCell>
                        <Table.HeaderCell>Race #</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>R.T</Table.HeaderCell>
                        <Table.HeaderCell>E.T</Table.HeaderCell>
                        <Table.HeaderCell>MPH</Table.HeaderCell>
                        <Table.HeaderCell collapsing></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <TableBody>
                    {!loadingData && data.length === 0 && (
                        <TableRow>
                            <TableCell textAlign="center" colSpan='7'>No data found</TableCell>
                        </TableRow>
                    )}

                    {data.map((pair) => (
                        <DataTableRowPair key={pair.timestamp} pair={pair} />
                    ))}

                    {loadingData && (
                        <Table.Row textAlign='center'>
                            <Table.Cell colSpan='6'>
                                <LoadingSpinner active text='Loading data' inline={true} />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </TableBody>
                <TableFooter>
                    <Table.Row>
                        <Table.Cell colSpan='7'>
                            Page: {pageMetaData?.currentPage + 1} of {pageMetaData?.totalPages} (
                            {pageMetaData?.totalCount} total)
                        </Table.Cell>
                    </Table.Row>
                </TableFooter>
            </Table>
        </InfiniteScroll>
    );
});
