import { Grid, Container, Header } from "semantic-ui-react";
import ContactForm from "./ContactForm";

export default function ContactPage() {
    return (
        <Grid>
            <Grid.Column>
                <Header color="blue" style={{fontSize: "2.5rem"}}>Get in touch</Header>
                <Header style={{fontSize: "1.5rem"}} sub>Email: <a href="mailto:racecontrol@santapod.com">racecontrol@santapod.com</a></Header>
                <Container style={{ marginTop: "5em" }}>
                    <ContactForm />
                </Container>
            </Grid.Column>
        </Grid>
    );
}
