import axios, { AxiosResponse } from "axios";
import { Category } from "../models/Category";
import { PagedList } from "../models/PagedList";
import { Pair } from "../models/Pair";
import { Prefix } from "../models/Prefix";
import { RaceEvent } from "../models/RaceEvent";
import { Run } from "../models/Run";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev api url
    axios.defaults.baseURL = "http://localhost:7071/api";
} else {
    // production api url
    axios.defaults.baseURL = "/api";
}

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
};

const Search = {
    listPairs: (params: URLSearchParams) => axios.get<PagedList<Pair>>("/ListPairs", { params }).then(responseBody),
    filterRuns: (params: URLSearchParams) => axios.get<PagedList<Pair>>("/FilterRuns", { params }).then(responseBody),
    getRun: (params: URLSearchParams) => axios.get<Run>("/GetRun", { params }).then(responseBody),
};

const Filters = {
    listRaceEvents: () => requests.get<RaceEvent[]>("/ListEvents"),
    listCategories: () => requests.get<Category[]>("/ListCategories"),
    listPrefixes: () => requests.get<Prefix[]>("/ListPrefixes"),
    listRounds: () => requests.get<string[]>("/ListRounds"),
};

// const Contact = {
//     Send: (contact: Contact) => requests.post<void>("/contact", contact),
// };

const agent = {
    Search,
    Filters,
};

export default agent;
