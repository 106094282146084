import React, { useEffect } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import { Container } from "semantic-ui-react";
import ModalContainer from "../common/modals/ModalContainer";
import SearchPage from "../../features/search/SearchPage";
import ContactPage from "../../features/contact/ContactPage";
import { Route, Routes } from "react-router-dom";

function App() {
    useEffect(() => {
        axios.get("http://localhost:7071/api/ListPairs").then((response) => {
            console.log(response);
        });
    });

    return (
        <Container style={{ marginTop: "7em" }}>
            <Navbar />
            <ModalContainer />
            <Routes>
                <Route path='/' element={<SearchPage />} />
                <Route path='/contact' element={<ContactPage />} />
            </Routes>
        </Container>
    );
}

export default App;
