import { Table } from "semantic-ui-react";

interface Props {
    leftValue: string | number | undefined;
    centerValue: string | number | undefined;
    rightValue: string | number | undefined;
    dp?: number;
}

// Vertical
export default function VerticalTableRow({ leftValue, centerValue, rightValue, dp = 4 }: Props) {
    function formatValue(value: number | undefined | string, dp: number) {
        if (typeof value === "undefined") return "";
        if (typeof value === "string") return value;
        if (value === null) return "";
        if (value === 0) return "";
        return value.toFixed(dp);
    }

    return (
        <Table.Row>
            <Table.Cell textAlign='center'>{formatValue(leftValue, dp)}</Table.Cell>
            <Table.Cell textAlign='center'>
                <strong>{centerValue}</strong>
            </Table.Cell>
            <Table.Cell textAlign='center'>{formatValue(rightValue, dp)}</Table.Cell>
        </Table.Row>
    );
}
