import { observer } from "mobx-react-lite";
import { Container, Grid, Image } from "semantic-ui-react";
import DataTable from "./DataTable";
import Filter from "./Filter";

export default observer(function SearchPage() {
    return (
        <Grid>
            <Grid.Column>
                <Image fluid src='/banner.jpg' style={{ maxHeight: "400px", maxWidth: "1200px" }} />
                <Container style={{ marginTop: "2em" }}>
                    <Filter />
                </Container>
                <Container style={{ marginTop: "5em" }}>
                    <DataTable />
                </Container>
            </Grid.Column>
        </Grid>
    );
});
