import { NavLink } from "react-router-dom";
import { Container, Menu } from "semantic-ui-react";

export default function Navbar() {
    return (
        <Menu inverted fixed='top'>
            <Container>
                <Menu.Item header as={NavLink} exact to='/'>
                    {/* <img src='/assets/logo.png' alt='logo' style={{ marginRight: "10px" }} /> */}
                    ETSlips.co.uk
                </Menu.Item>
                <Menu.Item name='Timing Tickets' as={NavLink} to='/' exact />
                <Menu.Item name='Contact' as={NavLink} to='/contact' />
                <Menu.Item name='Santa Pod Raceway' as='a' href='https://santapod.co.uk' target='_blank' />
                <Menu.Item name='SPRC Racers Club' as='a' href='https://www.santapodracersclub.com/' target='_blank' />
                <Menu.Item name='Eurodragster' as='a' href='http://www.eurodragster.com/' target='_blank' />
            </Container>
        </Menu>
    );
}
