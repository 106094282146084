import { useField } from "formik";
import { Dropdown, DropdownItemProps, Form, Label } from "semantic-ui-react";

interface Props {
    placeholder: string;
    name: string;
    options: Map<any, string>;
    label?: string;
    loading: boolean;
}

export default function MultiSelect(props: Props) {
    function toDropDownProps(map: Map<any, string>) {
        let dropdown: DropdownItemProps[] = [];

        map.forEach((key: any, value: string) => {
            dropdown.push({ value: value, text: key });
        });

        return dropdown;
    }

    const [field, meta, helpers] = useField(props.name);

    return (
        <Form.Field>
            <label>{props.label}</label>
            <Dropdown
                placeholder={props.placeholder}
                multiple
                search
                selection
                loading={props.loading}
                options={toDropDownProps(props.options)}
                disabled={props.loading}
                value={field.value || []}
                onChange={(e, d) => helpers.setValue(d.value)}
                onBlur={() => helpers.setTouched(true)}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>
                    {meta.error}
                </Label>
            ) : null}
        </Form.Field>
    );
}
