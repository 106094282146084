import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import { Form, Button, Header } from "semantic-ui-react";
import * as Yup from "yup";
import MultiSelect from "../../app/common/form/MultiSelect";
import Textbox from "../../app/common/form/Textbox";
import { laneOptions } from "../../app/common/options/laneOptions";
import { trackLengths } from "../../app/common/options/trackLengths";
import { useStore } from "../../app/stores/store";
import { Filter } from "../../app/models/Filter";
import Select from "../../app/common/form/Select";

export default observer(function Filter() {
    const { filterStore, dataStore } = useStore();
    const {
        loadCategories,
        loadRaceEvents,
        loadPrefixes,
        loadRounds,
        eventOptions,
        categoryOptions,
        prefixOptions,
        roundOptions,
        loadingRaceEvents,
        loadingCategories,
        loadingPrefixes,
        loadingRounds,
        toggleFilter,
        advanceFilterOpen,
    } = filterStore;

    const [filter] = useState<Filter>({
        pageNumber: 1,
        pageSize: 25,
        racenumber: "",
        events: [],
        categories: [],
        prefixes: [],
        rounds: [],
        minET: "",
        maxET: "",
        minMPH: "",
        maxMPH: "",
        lane: "_",
        trackLength: "99",
    });

    const validationSchema = Yup.object({
        racenumber: Yup.string().uppercase(),
        events: Yup.array().of(Yup.number().min(0)).notRequired(),
        categories: Yup.array().of(Yup.number().min(0)).notRequired(),
        prefixes: Yup.array().of(Yup.number().min(0)).notRequired(),
        rounds: Yup.array().of(Yup.string()).notRequired(),
        minET: Yup.number().min(0, "Minimum ET must be greater than 0").notRequired(),
        maxET: Yup.number().max(32, "Maximum ET must be less than 32").notRequired(),
        minMPH: Yup.number().min(0, "Minimum MPH must be greater than 0").notRequired(),
        maxMPH: Yup.number().max(350, "Maximum MPH must be less than 350").notRequired(),
    });

    useEffect(() => {
        if (eventOptions.size === 0) {
            loadRaceEvents();
            loadCategories();
        }
        if (prefixOptions.size === 0 && advanceFilterOpen) {
            loadPrefixes();
            loadRounds();
        }
    }, [loadRaceEvents, loadCategories, eventOptions.size, loadPrefixes, loadRounds, prefixOptions.size, advanceFilterOpen]);

    function handleFormSumbit(filter: Filter) {
        dataStore.reset();
        dataStore.filterData(filter);
    }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={filter}
            onSubmit={(values) => handleFormSumbit(values)}
        >
            {({ handleSubmit, dirty, isValid }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header size='huge' color='blue'>
                        Filter
                    </Header>
                    <Textbox
                        placeholder='Registration Plate, Racenumber or Drivername'
                        label='Search:'
                        name='racenumber'
                    />
                    <Form.Group widths='equal'>
                        <MultiSelect
                            label='Events:'
                            loading={loadingRaceEvents}
                            options={eventOptions}
                            placeholder='Select an events here'
                            name='events'
                        />
                        <Form.Field>
                            <MultiSelect
                                label='Categories:'
                                placeholder='Select a category here'
                                loading={loadingCategories}
                                options={categoryOptions}
                                name='categories'
                            />
                        </Form.Field>
                    </Form.Group>
                    {advanceFilterOpen && (
                        <Fragment>
                            <Form.Group widths='equal'>
                                <MultiSelect
                                    label='Prefixes:'
                                    placeholder='Select a prefix'
                                    loading={loadingPrefixes}
                                    options={prefixOptions}
                                    name='prefixes'
                                />
                                <MultiSelect
                                    placeholder='Select a round here'
                                    loading={loadingRounds}
                                    options={roundOptions}
                                    label='Rounds:'
                                    name='rounds'
                                />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Textbox label='Min ET:' placeholder='Min ET' name='minET' />
                                <Textbox label='Max ET:' placeholder='Max ET' name='maxET' />
                                <Textbox label='Min Mph:' placeholder='Min MPH' name='minMPH' />
                                <Textbox label='Max Mph:' placeholder='Max MPH' name='maxMPH' />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Select options={laneOptions} label='Lanes:' name='lane' />
                                <Select options={trackLengths} label='Track Lengths:' name='trackLength' />
                            </Form.Group>
                        </Fragment>
                    )}
                    <Button floated='right' primary type='submit' disabled={!dirty || !isValid}>
                        Search
                    </Button>
                    <Button onClick={toggleFilter} type='button' floated='right'>
                        {advanceFilterOpen ? "Quick Search" : "Advanced Search"}
                    </Button>
                </Form>
            )}
        </Formik>
    );
});
