export default function ContactForm() {
    // const [contact] = useState<Contact>({
    //     firstname: "",
    //     lastname: "",
    //     email: "",
    //     reason: "",
    //     message: "",
    // });

    // const validationSchema = Yup.object({
    //     firstname: Yup.string().required(),
    //     lastname: Yup.string().required(),
    //     email: Yup.string().required().email(),
    //     reason: Yup.string().required(),
    //     message: Yup.string().required(),
    // });

    // function handleFormSumbit(contact: Contact) {}

    return null;

    // return (
    //     <Formik
    //         validationSchema={validationSchema}
    //         initialValues={contact}
    //         onSubmit={(values) => handleFormSumbit(values)}
    //     >
    //         {({ handleSubmit, dirty, isValid }) => (
    //             <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>

    //             </Form>
    //         )}
    //     </Formik>
    // );
}
