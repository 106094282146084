import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { Pair } from "../../../app/models/Pair";
import { useStore } from "../../../app/stores/store";
import DetailedPair from "../detailed/DetailedPair";
import DataColumns from "./DataColumns";
import NoCarStagedColumn from "./NoCarStagedColumn";
import RunAbortedColumn from "./RunAbortedColumn";

interface Props {
    pair: Pair;
}

export default observer(function DataTableRowPair({ pair }: Props) {
    const { modalStore, dataStore } = useStore();

    function handleSelectedSelected() {
        dataStore.run = null;
        dataStore.setSelectedPair(pair);
        modalStore.openModal(<DetailedPair />);
    }

    return (
        <Fragment>
            {pair.left && (
                <Table.Row>
                    <Table.Cell>{format(new Date(pair.timestamp), "HH:mm dd/MM/yy")}</Table.Cell>
                    <Table.Cell>{pair.left.racenumber}</Table.Cell>
                    <Table.Cell>{pair.left.drivername}</Table.Cell>
                    {pair.left.result === "NCS" && <NoCarStagedColumn />}
                    {pair.left.remarks === "Run Aborted" && pair.left.result !== "NCS" && (
                        <Fragment>
                            <Table.Cell>{pair.left.reaction.toFixed(4)}</Table.Cell>
                            <RunAbortedColumn />
                        </Fragment>
                    )}
                    {pair.left.remarks !== "Run Aborted" && pair.left.result !== "NCS" && (
                        <DataColumns trackLength={pair.finish} run={pair.left} />
                    )}

                    <Table.Cell>
                        <Button style={{ padding: "5px" }} size='mini' primary onClick={handleSelectedSelected}>
                            <Icon style={{ padding: "0px" }} name='search' fitted />
                        </Button>
                    </Table.Cell>
                </Table.Row>
            )}
            {pair.right && (
                <Table.Row>
                    <Table.Cell>{format(new Date(pair.timestamp), "HH:mm dd/MM/yy")}</Table.Cell>
                    <Table.Cell>{pair.right.racenumber}</Table.Cell>
                    <Table.Cell>{pair.right.drivername}</Table.Cell>
                    {pair.right.result === "NCS" && <NoCarStagedColumn />}
                    {pair.right.remarks === "Run Aborted" && pair.right.result !== "NCS" && (
                        <Fragment>
                            <Table.Cell>{pair.right.reaction.toFixed(4)}</Table.Cell>
                            <RunAbortedColumn />
                        </Fragment>
                    )}
                    {pair.right.remarks !== "Run Aborted" && pair.right.result !== "NCS" && (
                        <DataColumns trackLength={pair.finish} run={pair.right} />
                    )}
                    <Table.Cell>
                        <Button style={{ padding: "5px" }} size='mini' primary onClick={handleSelectedSelected}>
                            <Icon style={{ padding: "0px" }} name='search' fitted />
                        </Button>{" "}
                    </Table.Cell>
                </Table.Row>
            )}
        </Fragment>
    );
});
