import { Loader } from "semantic-ui-react";

interface Props {
    active: boolean;
    text: string;
    inline: boolean;
}

export default function LoadingSpinner({ active, text, inline }: Props) {
    return <Loader active={active} content={text} inline={inline} />;
}
