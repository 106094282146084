import { useField } from "formik";
import { Dropdown, Form, Label } from "semantic-ui-react";

interface Props {
    options: any[];
    label: string;
    name: string;
}

export default function Select(props: Props) {
    const [field, meta, helpers] = useField(props.name);

    return (
        <Form.Field>
            <label>{props.label}</label>
            <Dropdown
                selection
                options={props.options}
                value={field.value}
                onChange={(e, d) => helpers.setValue(d.value)}
                onBlur={() => helpers.setTouched(true)}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>
                    {meta.error}
                </Label>
            ) : null}
        </Form.Field>
    );
}
