import { createContext, useContext } from "react";
import DataStore from "./dataStore";
import FilterStore from "./filterStore";
import ModalStore from "./modelStore";

interface Store {
    modalStore: ModalStore;
    filterStore: FilterStore;
    dataStore: DataStore;
}

export const store: Store = {
    modalStore: new ModalStore(),
    filterStore: new FilterStore(),
    dataStore: new DataStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
