import { Fragment } from "react";
import { Table } from "semantic-ui-react";
import { Run } from "../../../app/models/Run";

interface Props {
    run: Run;
    trackLength: number;
}

export default function DataColumn({ run, trackLength }: Props) {
    function getFinishET() {
        if (trackLength === 1) return run.eT660;
        if (trackLength === 2) return run.eT1000;
        if (trackLength === 3) return run.eT1320;
    }
    
    function getFinishMPH() {
        if (trackLength === 1) return run.sP660;
        if (trackLength === 2) return run.sP1000;
        if (trackLength === 3) return run.sP1320;
    }

    return (
        <Fragment>
            <Table.Cell>{run.reaction.toFixed(4)}</Table.Cell>
            <Table.Cell>{getFinishET()?.toFixed(4)}</Table.Cell>
            <Table.Cell>{getFinishMPH()?.toFixed(2)}</Table.Cell>
        </Fragment>
    );
}
